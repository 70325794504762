import React from "react";
import PageBanner from "../Components/PageBanner.jsx";
import { EN } from "../locale/EN.js";

const ManagementCoursePage = () => {
  return (
    <div>
      <PageBanner imgUrl="/gim.jpg" title="Management Courses" />
      Management Courses
    </div>
  );
};

export default ManagementCoursePage;
