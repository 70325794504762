import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Endpoints } from "../constants/Endpoints.js";
import axios from "axios";
import { processData } from "../utils.js";

const CoursesComponent = ({ limit }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.BASE_URL}/courses/listing`);
        let formatted = processData(res.data.data);
        setData(formatted);
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, []);

  // Function to filter courses by category
  const getCoursesByCategory = (categoryName) => {
    const category = data.find(
      (item) =>
        item.name?.toLowerCase().trim() === categoryName.toLowerCase().trim()
    );

    return category
      ? category.data.slice(0, 5).map((course) => course.title)
      : [];
  };

  const cards = [
    {
      id: 1,
      title: "Pre-Sea Courses",
      link: "pre-sea-courses",
      image: "/assets/course1.jpg",
      description: "Get ready for a maritime career with our Pre-Sea Courses.",
      courses: getCoursesByCategory("Pre-sea course"),
    },
    {
      id: 2,
      title: "Post-Sea Courses",
      link: "post-sea-courses",
      image: "/assets/course2.jpg",
      description: "Advance your maritime skills with our Post-Sea Courses.",
      courses: getCoursesByCategory("POST-SEA COURSE"),
    },
    {
      id: 3,
      title: "Value-Added Courses",
      link: "value-added-courses",
      image: "/assets/course3.jpg",
      description: "Enhance your knowledge with our Value-Added Courses.",
      courses: getCoursesByCategory("VALUE-ADDED COURSES"),
    },
    {
      id: 4,
      title: "Management Courses",
      link: "management-courses",
      image: "/assets/course4.jpg",
      description: "Enhance your knowledge with our Management Courses.",
      courses: getCoursesByCategory("MANAGEMENT COURSES"),
    },
  ];

  const handleViewMore = (cardId) => {
    navigate(`/courses/${cardId}`);
  };

  return (
    <div className="container mx-auto my-14 px-3">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:p-6 mb-5">
        {cards.map((card) => (
          <div
            key={card.id}
            onClick={() => handleViewMore(card.link)}
            className="h-80 md:h-[90vh] w-full bg-white rounded-2xl flex flex-col justify-between p-6 bg-cover bg-center shadow-lg relative group transition-all duration-500"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 bg-cover bg-center rounded-2xl transition-opacity duration-500 group-hover:opacity-0"
              style={{ backgroundImage: `url(${card.image})` }}
            ></div>

            {/* Hover Overlay */}
            <div className="absolute inset-0 bg-[#32586D] rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>

            {/* Top Gradient Overlay */}
            <div className="absolute inset-x-0 top-0 h-1/4 bg-gradient-to-b from-black/60 to-transparent rounded-t-2xl"></div>

            {/* Title and Icon */}
            <div className="relative flex justify-between items-center p-1">
              <h2 className="text-2xl font-medium text-white group-hover:font-semibold">
                {card.title}
              </h2>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="h-10 w-10 text-white group-hover:hidden bg-[#32586D] rounded-full p-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="h-10 w-10 text-white hidden group-hover:block bg-[#4b7b96] rounded-full p-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            </div>

            {/* Courses List */}
            <div className="absolute top-20 left-6 right-6 p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
              <ul className="text-white space-y-2">
                {card.courses.map((course, index) => (
                  <li key={index} className="text-lg font-extralight">
                    {course}
                  </li>
                ))}
              </ul>
              <button
                onClick={() => handleViewMore(card.link)}
                className="mt-5 text-white text-lg font-semibold underline"
              >
                View More
              </button>
            </div>

            {/* Bottom Gradient Overlay */}
            <div className="absolute inset-x-0 bottom-0 h-1/4 bg-gradient-to-t from-black/60 to-transparent rounded-b-2xl"></div>

            {/* Description */}
            <div className="relative group-hover:hidden mt-4">
              <p className="text-white text-lg font-light">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesComponent;
