import axios from "axios";
import { useState, useEffect } from "react";
import { Endpoints } from "../constants/Endpoints.js";

const NewsletterPopup = ({ profileDetails }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    if (!profileDetails) {
      const lastDismissal = localStorage.getItem("popupDismissalTime");
      const twentyFourHours = 24 * 60 * 60 * 1000;

      if (!lastDismissal || Date.now() - lastDismissal > twentyFourHours) {
        setIsOpen(true);
      }
    }
  }, [profileDetails]);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem("popupDismissalTime", Date.now());
  };

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Form validation
    if (
      !formData.name ||
      !formData.email ||
      !formData.mobile ||
      !formData.consent
    ) {
      alert("Please fill out all required fields and agree to the terms.");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Validate mobile number format (basic validation for 10 digits)
    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(formData.mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    try {
      const response = await axios.post(
        `${Endpoints.API_URL}/candidate/subscriber`,
        {
          name: formData.name,
          email: formData.email,
          number: formData.mobile,
        }
      );

      if (response.status === 200) {
        handleClose();

        alert("Thank you for subscribing! You will receive updates shortly.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
  <div className="bg-white rounded-lg flex flex-col md:flex-row max-w-2xl w-full max-h-[90vh] overflow-hidden">
    {/* Image Section - Reduced width */}
    <div className="w-full md:w-2/5 h-48 md:h-auto flex-shrink-0">
      <img
        src="/assets/course4.jpg"
        alt="EuroTech Maritime Academy Offers"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Content Section - Adjusted for no scroll on large screens */}
    <div className="w-full md:w-3/5 p-4 md:p-6 flex flex-col">
      <button
        onClick={handleClose}
        className="self-end text-gray-500 hover:text-gray-700 text-lg mb-2"
      >
        ×
      </button>

      <div className="flex-1">
        <h2 className="text-lg md:text-xl font-semibold mb-3">
          Get Exclusive Offers & Updates!
        </h2>
        <p className="text-xs md:text-sm text-gray-600 mb-4 leading-5">
          Stay ahead with the latest courses, special discounts, and
          important updates from EuroTech Maritime Academy. Sign up now to
          receive exclusive offers directly in your inbox!
        </p>

        <form onSubmit={handleSubmit}>
          <div className="space-y-3">
            <input
              type="text"
              name="name"
              placeholder="Name *"
              required
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded text-xs md:text-sm focus:ring-2 focus:ring-blue-500"
            />

            <input
              type="email"
              name="email"
              placeholder="Email *"
              required
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded text-xs md:text-sm focus:ring-2 focus:ring-blue-500"
            />

            <input
              type="tel"
              name="mobile"
              placeholder="Mobile Number *"
              required
              value={formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border rounded text-xs md:text-sm focus:ring-2 focus:ring-blue-500"
            />

            <label className="flex items-start text-xs space-x-2">
              <input
                type="checkbox"
                name="consent"
                required
                checked={formData.consent}
                onChange={handleChange}
                className="mt-1 w-3 h-3 accent-blue-600"
              />
              <span>
                I agree to receive updates and promotional messages from
                EuroTech Maritime Academy.
              </span>
            </label>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 text-xs md:text-sm font-medium transition-colors"
            >
              Subscribe Now
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  );
};

export default NewsletterPopup;
