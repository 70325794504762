import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner.jsx";
import CoursesItem from "../Components/CoursesItem.jsx";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints.js";

const ValueAddedPage = () => {
  const [preSea, setPreSea] = useState([]);
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.BASE_URL}/courses/listing`);
        setRawData(res.data.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    const allArrays = [];

    for (const key in rawData) {
      if (Array.isArray(rawData[key])) {
        allArrays.push(...rawData[key]);
      }
    }

    let preSea = allArrays.filter((item) =>
      item?.category?.is_primary === 1
        ? item?.category?.primary_category === ""
        : item?.category?.name === "Value Added - NON DGS Courses"
    );

    setPreSea(preSea);
  }, [rawData]);

  return (
    <div>
      <PageBanner imgUrl="/gim.jpg" title="Value added Courses" />
      <div className="container mx-auto my-14 px-3">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {preSea?.slice(0).map((item, index) => (
            <CoursesItem
              index={index}
              title={item.title}
              key={index}
              data={item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValueAddedPage;
